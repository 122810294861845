import React from "react"
import { makeStyles, Typography, useTheme, Link } from "@material-ui/core"

import { RoughNotation } from "react-rough-notation"
import Arrow from "./assets/arrow.svg"

export default function Hightlight({
  firstText,
  secondText,
  highlightText,
  highlightLink,
}) {
  const classes = useStyle()
  const theme = useTheme()
  return (
    <div className={classes.container}>
      <Typography className={classes.text}>{firstText}</Typography>
      {secondText && secondText !== "" && (
        <Typography className={classes.text}>{secondText}</Typography>
      )}
      <div className={classes.highlightTextContainer}>
        <RoughNotation
          iterations={4}
          type="highlight"
          color={theme.palette.yellow}
          show
        >
          <Typography className={classes.highlightText}>
            {highlightText}
          </Typography>
        </RoughNotation>
        {highlightLink && Object.keys(highlightLink).length > 0 && (
          <Link
            href={highlightLink.url}
            variant="body"
            color="secondary"
            className={classes.subtitle}
          >
            {highlightLink.title}
            <Arrow className={classes.arrow} />
          </Link>
        )}
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  highlightTextContainer: {
    margin: "auto",
    maxWidth: 500,
    marginTop: 72,
    position: "relative",
  },
  container: {
    maxWidth: 750,
    margin: "auto",
    paddingTop: 100,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: 60,
      paddingBottom: 60,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  text: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 23,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
    },
  },
  highlightText: {
    margin: "auto",
    color: theme.palette.secondary.main,
    textAlign: "center",
    fontSize: 26,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      marginTop: theme.spacing(5),
    },
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    color: theme.palette.text.turquoise,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0),
    },
  },
}))
