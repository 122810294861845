import * as React from "react"
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core"
import { Arrow } from "./Arrow"
import gridPrimary from "!!url-loader!./assets/grid-primary.svg"
import gridSecondary from "!!url-loader!./assets/grid-secondary.svg"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

const useThemeFromColor = color => {
  const theme = useTheme()
  const chartColor = {
    Green: {
      primaryBackgroundColor: theme.palette.secondary.main,
      secondaryBackgroundColor: theme.palette.common.white,
      text: theme.palette.primary.main,
      gridBackground: gridPrimary,
    },
    Pink: {
      primaryBackgroundColor: theme.palette.primary.main,
      secondaryBackgroundColor: theme.palette.common.white,
      text: theme.palette.secondary.main,
      gridBackground: gridSecondary,
    },
    Yellow: {
      primaryBackgroundColor: theme.palette.yellow,
      secondaryBackgroundColor: theme.palette.common.white,
      text: theme.palette.secondary.main,
      gridBackground: gridSecondary,
    },
    White: {
      primaryBackgroundColor: theme.palette.common.white,
      secondaryBackgroundColor: theme.palette.primary.main,
      text: theme.palette.secondary.main,
      gridBackground: gridSecondary,
    },
  }

  return chartColor[color || "Green"]
}

export default function TopBlock({
  backgroundColor: bgc,
  backgroundImageTopBloc,
  image,
  title,
  subtitle,
}) {
  const {
    primaryBackgroundColor,
    secondaryBackgroundColor,
    text,
    gridBackground,
  } = useThemeFromColor(bgc)
  const isCentered = !Boolean(image)
  const classes = useStyles({
    text,
    image,
    primaryBackgroundColor,
    secondaryBackgroundColor,
    backgroundImageTopBloc,
    isCentered,
    gridBackground,
  })
  return (
    <div className={classes.container}>
      <Container maxWidth="md">
        {isCentered && (
          <Box pt={{ xs: 10, sm: 20 }}>
            <Grid
              container
              justify="center"
              alignItems="center"
              className={classes.content}
            >
              <div className={classes.backgroundImageTopBlocContainer}>
                <ImageWithLazyload src={backgroundImageTopBloc} />
              </div>
              <Grid item>
                <Box
                  pl={{ sm: 15, md: 35 }}
                  pr={{ sm: 15, md: 35 }}
                  py={{ md: 16 }}
                  position="relative"
                >
                  <Typography variant="h1" className={classes.title}>
                    {title}
                  </Typography>
                  <Box mt={2}>
                    <Typography variant="body1">{subtitle}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <div className={classes.arrow}>
              <Arrow
                primaryColor={secondaryBackgroundColor}
                secondaryColor={primaryBackgroundColor}
              />
            </div>
          </Box>
        )}
        {!isCentered && (
          <Box pt={18}>
            <Grid container justify="center" className={classes.content}>
              <Grid item sm={12} md={6}>
                <Box pr={{ sm: 10, md: 6 }} py={{ md: 16 }}>
                  <Typography variant="h1" className={classes.title}>
                    {title}
                  </Typography>
                  <Box mt={3}>
                    <Typography variant="body1">{subtitle}</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                sm={12}
                md={6}
                container
                className={classes.imgContainer}
              >
                <div className={classes.imageContainer}>
                  <ImageWithLazyload
                    alt="header image"
                    src={image}
                    className={classes.image}
                  />
                </div>
                <div className={classes.arrowAbsolute}>
                  <Arrow
                    primaryColor={secondaryBackgroundColor}
                    secondaryColor={primaryBackgroundColor}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        )}
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: ({
    text,
    primaryBackgroundColor,
    secondaryBackgroundColor,
    isCentered,
  }) => ({
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      background: `linear-gradient(180deg, ${primaryBackgroundColor} ${
        isCentered ? "602px" : "90%"
      }, ${secondaryBackgroundColor} 0)`,
    },
    background: `linear-gradient(180deg, ${primaryBackgroundColor} ${
      isCentered ? "521px" : "650px"
    }, ${secondaryBackgroundColor} 0)`,
    color: text,
  }),
  content: {
    minHeight: 442,
    position: "relative",
  },
  backgroundImageTopBlocContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
  },
  title: ({ text }) => ({
    color: text,
    fontSize: 40,
    letterSpacing: "-3px",
    lineHeight: "100%",
    [theme.breakpoints.up("md")]: {
      fontSize: 48,
    },
  }),
  imgContainer: {
    "& > img": {
      width: "100%",
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      justifyContent: "flex-end",
      position: "initial",
    },
    position: "relative",
    marginTop: theme.spacing(7),
    justifyContent: "center",
  },
  arrow: {
    zIndex: 2,
    marginTop: -42,
    display: "flex",
    justifyContent: "center",
  },
  arrowAbsolute: {
    position: "absolute",
    zIndex: 2,
    top: -25,
    display: "flex",
    justifyItems: "center",
    [theme.breakpoints.up("md")]: {
      top: "calc(90% - 42px)",
      left: "calc(50% - 400px)",
    },
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: 560,
    justifyContent: "center",
    display: "flex",
    alignItems: "flex-end",
    "&:after": {
      content: "''",
      position: "absolute",
      top: -6,
      left: 2,
      right: 0,
      bottom: 80,
      backgroundImage: ({ gridBackground }) => `url(${gridBackground})`,
      backgroundRepeat: "repeat",
      [theme.breakpoints.down("sm")]: {
        top: -12,
        left: -8,
        backgroundRepeat: "repeat round",
      },
    },
    [theme.breakpoints.down("xs")]: {
      height: "100vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "95%",
    },
  },
  image: {
    zIndex: 1,
    [theme.breakpoints.up("sm")]: {
      padding: "30px 34px",
    },
    padding: "25px 28px",
    width: "90%",
    height: "96%",
    position: "relative",
  },
}))
