import React from "react"
import showdown from "showdown"
import { makeStyles, Typography, Button } from "@material-ui/core"
import useColor from "src/hooks/useColor"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

const converter = new showdown.Converter()

export default function Stepper({
  image,
  title,
  text,
  numberBackgroundColor,
  numberColor,
  isImageFirst,
  displayNumber,
  number,
  backgroundColor,
  ctaTitle,
  ctaLink,
}) {
  const blocBackgroundColor = useColor(backgroundColor)
  const color = useColor(numberColor)
  const numberBackgroundColorComputed = useColor(numberBackgroundColor)

  const classes = useStyle({
    isImageFirst,
    image,
    displayNumber,
    color,
    blocBackgroundColor,
    numberBackgroundColorComputed,
  })
  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <div className={classes.imageContainer}>
          <div className={classes.image}>
            <ImageWithLazyload src={image} className={classes.imageInner} />
          </div>
        </div>
        <div className={classes.textBloc}>
          <div className={classes.textBlocInner}>
            {displayNumber && (
              <div className={classes.numberContainer}>
                <div className={classes.number}>{number}</div>
              </div>
            )}
            <Typography
              variant="h1"
              component="h2"
              color="secondary"
              className={classes.title}
            >
              {title}
            </Typography>
            <Typography color="secondary" className={classes.text}>
              <div
                dangerouslySetInnerHTML={{ __html: converter.makeHtml(text) }}
              ></div>
            </Typography>
            {ctaTitle && (
              <Button variant="contained" color="primary" href={ctaLink}>
                {ctaTitle}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  title: { fontSize: 36 },
  text: {
    lineHeight: "140%",
    marginTop: theme.spacing(2),
    "& ul": {
      paddingLeft: 20,
      listStyle: "none",
    },
    "& ul > li:before": {
      content: "'·'",
      display: "inline-block",
      width: ".75em",
      marginLeft: "-.75em",
      fontSize: "2em",
      textAlign: "center",
      verticalAlign: "top",
    },
  },
  container: {
    backgroundColor: ({ blocBackgroundColor }) => blocBackgroundColor,
    paddingTop: 50,
    paddingBottom: 50,
  },
  inner: {
    maxWidth: 720,
    width: "100%",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: ({ isImageFirst }) =>
      isImageFirst ? "flex-start" : "flex-end",
    position: "relative",
    "@media (max-width:580px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  numberContainer: {
    position: "absolute",
    top: -80,
    left: "10%",
    right: "10%",
    display: "flex",
    justifyContent: "center",
  },
  number: {
    width: 60,
    height: 60,
    color: ({ color }) => color,
    borderRadius: 50,
    border: `1px solid ${theme.palette.secondary.main}`,
    backgroundColor: ({ numberBackgroundColorComputed }) =>
      numberBackgroundColorComputed,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 700,
    fontSize: 30,
  },
  textBloc: {
    maxWidth: 430,
    backgroundColor: "white",
    zIndex: 2,
    padding: 50,
    position: "absolute",
    right: ({ isImageFirst }) => (isImageFirst ? 0 : "unset"),
    left: ({ isImageFirst }) => (!isImageFirst ? 0 : "unset"),
    "@media (max-width:580px)": {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      position: "unset",
      padding: ({ displayNumber }) => (displayNumber ? 50 : 20),
      paddingTop: 20,
      width: `calc(100% - ${theme.spacing(4)}px)`,
      maxWidth: "unset",
      marginBottom: -60,
      order: 0,
    },
  },
  textBlocInner: {
    position: "relative",
  },
  imageContainer: {
    width: "55%",
    paddingBottom: "64%",
    position: "relative",
    "@media (max-width:580px)": {
      width: "100%",
      paddingBottom: "150%",
      order: 1,
    },
  },
  image: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    hustifyContent: "center",
  },
  imageInner: {
    minWidth: "100%",
    minHeight: "100%",
  },
}))
