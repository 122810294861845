import React from "react"
import { makeStyles, Container, Typography } from "@material-ui/core"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

function Slide({ slide }) {
  const classes = useStyle()
  return (
    <div className={classes.slide}>
      <div className={classes.picture}>
        <ImageWithLazyload src={slide.backgroundImage} />
      </div>
      <Typography
        color="secondary"
        variant="h1"
        component="h3"
        className={classes.name}
      >
        {slide.name}
      </Typography>
      <Typography variant="caption" className={classes.text}>
        {slide.description}
      </Typography>
    </div>
  )
}

export default function SliderNosHeros({ nosHerosSlides, title }) {
  const classes = useStyle()
  return (
    <div>
      <Container maxWidth="md">
        <Typography variant="h2" className={classes.title} color="secondary">
          {title}
        </Typography>
      </Container>
      <div className={classes.block}>
        <Container maxWidth="md" className={classes.container}>
          <div className={classes.inner}>
            {nosHerosSlides.map(slide => (
              <Slide slide={slide} />
            ))}
          </div>
        </Container>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  block: {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      marginLeft: 500,
      marginRight: 500,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&::-webkit-scrollbar-track": {
        marginLeft: 200,
        marginRight: 200,
      },
    },
    "&:hover": {
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f6f6f6",
        border: "1px solid #f6f6f6",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  picture: {
    height: 510,
    width: 340,
    minHeight: 510,
    minWidth: 340,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 400,
      minWidth: "unset",
      boxSizing: "border-box",
      minHeight: "unset",
    },
  },
  inner: {
    display: "flex",
    position: "absolute",
    top: 16,
  },
  container: {
    position: "relative",
    height: 650,
    [theme.breakpoints.down("xs")]: {
      height: 570,
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 45,
  },
  name: {
    maxWidth: "90%",
    marginTop: theme.spacing(2),
    fontSize: 30,
  },
  text: {
    marginTop: theme.spacing(),
    fontSize: 16,
    fontWeight: 400,
  },
  slide: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "flex-start",
    marginRight: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      width: "min(80vw , 340px)",
      marginRight: theme.spacing(2),
    },
  },
}))
