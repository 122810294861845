import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import useColor from "src/hooks/useColor"

import Arrow from "./assets/arrow.svg"

export default function StepperTitleBloc({
  backgroundColor,
  color,
  title,
  text,
}) {
  const blocBackgroundColor = useColor(backgroundColor)
  const textColor = useColor(color)
  const classes = useStyle({ blocBackgroundColor, textColor })
  return (
    <div className={classes.container}>
      <Arrow className={classes.animationContainer} />
      <div className={classes.inner}>
        <Typography align="center" className={classes.title}>
          {title}
        </Typography>
        <Typography align="center" className={classes.text}>
          {text}
        </Typography>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  lottie: {
    width: 400,
    height: 400,
  },
  container: {
    backgroundColor: ({ blocBackgroundColor }) => blocBackgroundColor,
    paddingTop: 140,
    paddingBottom: 50,
    position: "relative",
  },
  inner: {
    maxWidth: "50%",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "80%",
    },
  },
  title: {
    color: ({ textColor }) => textColor,
    fontSize: 44,
    fontWeight: 700,
  },
  text: {
    color: ({ textColor }) => textColor,
    fontWeight: 500,
    fontSize: 26,
    [theme.breakpoints.down("xs")]: {
      fontSize: 22,
    },
  },
  animationContainer: {
    top: -80,
    position: "absolute",
    right: "max(calc(50% - 270px) , 24px)",
  },
}))
