import * as React from "react"

export const Arrow = ({ primaryColor, secondaryColor }) => {
  return (
    <svg
      width="80"
      height="113"
      viewBox="0 0 80 113"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M79.8 81.6C59.3 82.6 47.2 94.8 45.6 113H34.3C32.7 94.8 20.5 82.6 0 81.6L3.1 68.1C10.4 68.8 16.9 71.4 22.9 75.8C28.9 80.3 32.6 85.4 34.4 91.2V42.2H45.7V91.2C47.5 85.4 51.2 80.3 57 75.8C63 71.4 69.5 68.8 76.9 68.1L79.8 81.6Z"
          fill={secondaryColor}
        />
        <path d="M45.6 0H34.3V42.2H45.6V0Z" fill={primaryColor} />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="79.8" height="113" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
